import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// Make sure it hasn't already been initialized
if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  });
  if (process.env.NODE_ENV === 'development') {
    firebase.auth().useEmulator('http://localhost:9099');
    firebase.firestore().useEmulator('localhost', '8080');
  }
  
}

export default firebase;
